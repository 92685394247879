.search {
	margin-bottom: 40px;
}

.search__input {
	background: transparent;
	border: 2px solid #827474;
	padding: 0;
	height: 42px;
	width: 300px;
	padding-left: 10px;
	border-radius: 6px;
	color: #d9c1c1;
	transition: width 0.2s;
}

.search__input:focus {
	transition: width 0.2s;
	width: 100%;
}
